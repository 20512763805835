<template>
  <div class="main" ref="wrapper">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>

    <!-- <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="item in list" :key="item.id" @click="tabClick(item.urlPath)">
        <van-row>
          <van-col span="8">
            <img class="goodsimg" :src="item.imageUrl" />
          </van-col>
          <van-col span="16">
            <div></div>
          </van-col>
        </van-row>
      </div>
    </van-list> -->
  </div>
</template>

<script>
// import { getSchoolListAPI } from "../../api/seting";
export default {
  data() {
    return {
      body: {
        fromType: 1,
        current: 1,
        pageSize: 100,
        FilterData: {
          setTypes: [200, 201, 202, 203, 204, 205],
        },
      },
      title: "精品专区",
      list: [],
      loading: false,
      finished: false,
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    // async getGoodList() {
    //   let result = await getSchoolListAPI(this.body);
    //   let { data } = result.data;
    //   console.log(data);
    //   for (let index = 0; index < data.length; index++) {
    //     if (data[index].setType === 203) {
    //       this.list.push(data[index]);
    //     }
    //   }
    //   //   console.log(this.list);
    // },
    // onLoad() {
    //   // 异步更新数据
    //   // setTimeout 仅做示例，真实场景中一般为 ajax 请求
    //   this.getGoodList();
    //   // 加载状态结束
    //   this.loading = false;
    //   // 数据全部加载完成
    //   if (this.list.length >= 40) {
    //     this.finished = true;
    //   }
    // },
    // tabClick(link) {
    //   if (link != "") {
    //     if (link.indexOf("http") >= 0) {
    //       window.location.href = link;
    //     } else {
    //       this.$router.push(link);
    //       //存储 scrollTop 的值
    //       this.scroll = this.$refs.wrapper.scrollTop;
    //     }
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.goodsimg {
  width: 100px;
}
</style>
